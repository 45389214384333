.lia-tag {
  display: flex;
  font-size: var(--lia-bs-font-size-sm);
  column-gap: 10px;
}

.lia-tag-link {
  color: var(--lia-bs-body-color);
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: var(--lia-bs-body-color);
  }
}

.lia-byline-item {
  color: var(--lia-bs-text-muted);
}

.lia-tag-meta-data {
  margin-left: auto;
  flex-shrink: 0;
}

.lia-icon {
  margin: 5px 3px 0 0;
}

.lia-tag-text {
  display: grid;
  overflow-wrap: break-word;
}
